/* styles/InteractiveResumeBuilder.css */
.interactive-resume-builder {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    // border: 1px solid #ccc;
    border-radius: 10px;
    // background-color: #f9f9f9;
  }
  .interactive-resume-builder-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    display: 'flex';
    align-items: 'center';
    margin-bottom: '8px'
  }
  
  @media (max-width: 600px) { /* Adjust 600px based on your mobile breakpoint */
    .interactive-resume-builder-container {
      flex-direction: column;
      align-items: flex-start; /* Align items to the start of the container */
      display: flow-root;
    }
  }
  .interactive-resume-builder h2 {
    text-align: center;
  }
  
  .interactive-resume-builder form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .interactive-resume-builder .chatbot-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  .interactive-resume-builder button {
    padding: 10px;
    border: none;
    // border-radius: 5px;
    cursor: pointer;
  }
  
  .interactive-resume-builder button:disabled {
    background-color: #aaa;
  }
  
  .interactive-resume-builder .answer {
    margin-top: 20px;
  }
  
  .interactive-resume-builder .answer h3 {
    margin-bottom: 10px;
  }
  
  .interactive-resume-builder .answer {
    @media screen and (max-width: 600px) {
      p, ol, div {
        font-size: .8rem;
      }
    }
    ol {
      padding-left: 15px;
    }
  }
  
