@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");



body {
  font-family: "Nunito", "Comfortaa", cursive;
  color: black;

}

main {
  min-height: 80vh;
}

section {
  padding: 2.5vw 3.5vw 12vh 3.5vw;
  height: 100vh;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}