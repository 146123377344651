.icon {
    // width: 16px;
    // height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    &-glyph {
      font-size: 20px;
    }
  
    &-image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }