.time-line-container {

    // background-color: white;
    // box-shadow: 0px 2px 1px -1px rgb(1 1 1), 0px 1px 1px 0px rgb(0 0 0 / 14%), inset 5px 9px 14px 15px rgb(0 0 0 / 12%);
    // margin-top: 30px;
    // margin-bottom: 30px;
}

.page-container {
    // background-image: radial-gradient(#00000040, #10151b);

    .time-line-container {
        position: relative;
      }
      
      .title-container {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box-shadow for shadow-like effect */
      }
    
      .scrollable-container {
        overflow-y: auto;
        max-height: calc(100vh - 150px); /* Adjust this value as needed */
      }

    &.experience {
        overflow: hidden;
        // color: white;

        .scrollable-container {
            max-height: 1000px; /* Adjust the maximum height as needed */
            overflow-y: auto;
          }
          
          padding-bottom: 50px;
    }
    
    
}