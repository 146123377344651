.pageSlider-exit > .page {
    z-index: 1;
  }
  
  .pageSlider-enter > .page {
    z-index: 10;
  }
  
  .pageSlider-enter.left > .page {
    transform: translate3d(100%, 0, 0);
  }
  
  .pageSlider-enter.right > .page {
    transform: translate3d(-100%, 0, 0);
  }
  
  .pageSlider-exit.left > .page {
    transform: translate3d(-100%, 0, 0);
  }
  
  .pageSlider-exit.right > .page {
    transform: translate3d(100%, 0, 0);
  }
  
  .pageSlider-enter.pageSlider-enter-active > .page {
    transform: translate3d(0, 0, 0);
    transition: all 800ms ease-in-out;
  }