.welcome-container {
    width: 100%;
    height: 22.2em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 25px;
    border-radius: 3px;
    box-shadow: 0px 0px 11px 1px rgba(15, 15, 15, 0.2);
    font-size: 18px;
    background: linear-gradient(
        to right,
        rgba(90, 151, 206, 0.82),
        rgba(12, 12, 12, 0.68)
      );
    background-size: cover;
    color: #fff;
  }
  
  .our-story {
    font-size: 11px;
    font-weight: 100;
    font-family: monospace;
    inline-size: 66%;
    line-height: 1.5;
    margin: auto;
  }

  .parent {
    display: grid;
    place-items: center;
  }

  @media (min-width: 768px) {
    .parent {
      justify-content: center;
    }
  }
  
  @media (min-height: 500px) {
    .parent {
      align-items: center;
    }
  }
.iconStyle {
  width: 70px !important;

  @media (max-width: 600px) {
    width: 50px !important;
   }

   @media (min-width: 601px) and (max-width: 900px) {
    width: 60px !important;
   }

   @media (min-width: 901px) and (max-width: 1200px) {
    width: 70px !important;
   }
}



  