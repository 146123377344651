.about-me-description {
  box-shadow: 1px 20px 20px 0px rgb(255 0 0 / 20%);
}

.element {
  position: relative;
  offset: 50px 0;
}

#aboutMe {

  // @media only screen and (max-width: 1169px) {
  //     .middle.about-me-description  p {
  //       font-size: .8rem !important;
  //     }
  //   }
  .middle.about-me-description p {
    @media (max-width: 600px) {
     font-size: 1rem !important;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 1rem !important;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: 1.5rem !important;
    }
  }

  // // background: white;
  // box-shadow: 1px 20px 20px 0px rgb(255 0 0 / 20%);
  // height: 100%;
}