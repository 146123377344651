.circle-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: auto;
    
    img {
      width: 100%;
      max-height: 500px;
      border-radius: 50%;
      box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.5);

     @media (max-width: 600px) {
      max-height: 200px;
      width: 70%;
    }
    @media (min-width: 601px) and (max-width: 900px) {
      max-height: 300px;
      width: 80%;

    }
    // @media (min-width: 901px) and (max-width: 1200px) {
    //     max-width: 100% !important;
    // }
    }
  }