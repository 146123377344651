// how to zooom out of parallax image so that it is not so close to the screen

.parallax-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    // text-shadow: 2px 2px black;
    font-size: 3rem;
    text-align: center;
    background-size: cover;
    background-position: center;
    position: relative;
  
    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    // }
  }
  
  .fade-in {
    animation: fade-in 0.5s ease-in-out forwards;
  }
  
  .fade-out {
    animation: fade-out 0.5s ease-in-out forwards;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  // make only odd childs with this class have this styling  
  
  .react-parallax:nth-child(odd) > img {
    object-fit: cover;
    height: 100%;
    position: relative;
    display: flex;
  }

  // .react-parallax:nth-child(odd) > img{
  //   top: 70%;
  // }
  

  
  .parallax-video {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // min-width: 100%;
    // min-height: 100%;
    // width: auto;
    // height: auto;
    // z-index: -1; /* Push the video behind other content */
  }
  
  .helloworldgif > img {
    @media (max-width: 600px) {
        max-width: 100% !important;
    }
    @media (min-width: 601px) and (max-width: 900px) {
        max-width: 100% !important;
    }
    @media (min-width: 901px) and (max-width: 1200px) {
        max-width: 100% !important;
    }
      position: relative !important;
      max-width: 30% !important;
      height: 100% !important;
      object-fit: fill !important;

  }

  .gradient-background {
    background: linear-gradient(76deg, rgba(58, 57, 57, 1) 0%, rgba(69, 69, 68, 1) 19%, rgba(255, 255, 255, 1) 46%);
  }