.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .project {
    width: 100%;
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    .project-image {
      width: 70%;
      height: auto;
      border-radius: 10px;
      margin-bottom: 20px;
    }
    .project-image-container{
      flex: 0 0 40%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .project-title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    .project-impact {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .project-description {
      padding-left: 25px;
      flex: 0 0 60%;

    }

    .responsibilities-achievements {
      list-style-type: disc;
      padding-left: 20px;
      text-align: left;

      li {
        margin-bottom: 5px;
      }
    }

    @media (max-width: 768px) {
      display: block;
      flex-direction: column;
    }

    @media (max-width: 768px) {
      .project-image-container,
      .project-description {
        flex: 0 0 100%;
      }
    }
  }
}