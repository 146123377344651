.title-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4.5rem;
    &.main-title{
        font-weight: bold;
    }

    &.sub-title {
        font-weight: 100 ;
    }

    @media only screen and (max-width: 768px)  {
        font-size: 3.5rem

    }

    @media only screen and (min-width: 1200px)
    {
        font-size: 4.5rem

    }
}
